import './Loading.scss'

export const Loading = ()=>{
    return (
        <div className="loading-wrapper">
            <div className='spinner'>
                
            </div>
        </div>
    )
}